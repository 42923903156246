
import { 
    createSlice, 
    createAsyncThunk,  
    createEntityAdapter,  
} from '@reduxjs/toolkit'

import Store from "../../../Store";

import { config } from '../../../config';
import { DeviceModel } from '../models/DeviceModel';

const authenticationAdapter = createEntityAdapter({})

const deviceModel = DeviceModel.getInstance();

export const deviceApi = {
    intialized: false,
    initDevice: createAsyncThunk(
        'identity/initDevice', async (_, thunkAPI) => {
            if(!deviceApi.intialized) {
                deviceApi.intialized = true
                
                if(!deviceModel.existsValidDevice()) {
                    await deviceModel.createDevice({enable_notification: false});
                } else {
                    await deviceModel.reloadDevice();
                }
            }

            return {
                device: deviceModel.getDevice(),
                identity: deviceModel.getIdentity(),
                token: deviceModel.getToken(),
            }
        }
    ),
    updateDevice: createAsyncThunk(
        'updateDevice/updateDevice', async ({enable_notification, notification_token, language}) => {
            await deviceModel.updateDevice(enable_notification, notification_token, language)

            return {
                device: deviceModel.getDevice(),
                identity: deviceModel.getIdentity(),
                token: deviceModel.getToken(),
            }
        }
    ),
    login: createAsyncThunk(
        'identity/login', 
        async ({username, password}, thunkAPI) => {
            try {
                await deviceModel.login(username, password);
                return {
                    device: deviceModel.getDevice(),
                    identity: deviceModel.getIdentity(),
                    token: deviceModel.getToken(),
                }
            } catch (apiError) {
                return thunkAPI.rejectWithValue(apiError.toJson());
            }
        }
    ),
    logout: createAsyncThunk(
        'identity/logout', async () => {
            const result = await deviceModel.logout();
            return result;
        }
    ),
    passwordRecovery: createAsyncThunk(
        'identity/recovery', async (email) => {
            const result = await deviceModel.passwordRecovery(email);
            return result;
        }
    ),
    updatePassword: createAsyncThunk(
        'identity/update', async ({email, oldPassword, newPassword}, thunkAPI) => {
            try {
                return await deviceModel.passwordUpdate(email, oldPassword, newPassword);
            } catch (apiError) {
                return thunkAPI.rejectWithValue(apiError.toJson());
            }
        }
    ),
    confirmIdentity: createAsyncThunk(
        'identity/confirm', async (code, thunkAPI) => {
            try {
                return await deviceModel.identityConfirmation(code);
            } catch (apiError) {
                return thunkAPI.rejectWithValue(apiError.toJson());
            }
        }
    ),
    getNewConfirmationCode: createAsyncThunk(
        'identity/confirm/new', async (email, thunkAPI) => {
            try {
                return await deviceModel.requestNewConfirmationCode(email);
            } catch (apiError) {
                return thunkAPI.rejectWithValue(apiError.toJson());
            }
        }
    ),
}

const identitySlice = createSlice({
    name: "authentication",
    initialState: authenticationAdapter.getInitialState({
      statusDevice: 'idle',
      status: 'idle',
      error: null,
      errorCode: null,
      device: null,
      identity: null,
      token: null,
      confirmStatus: null
    }),
    reducers: {
        setIdentityOnUserUpdate(state, action) {
            state.identity.firstname = action.payload.firstname;
            state.identity.lastname = action.payload.lastname;
            state.identity.email = action.payload.email;
            state.identity.avatar = action.payload.avatar;
        },
        refreshOnSync(state, action) {
            //console.log('refreshOnSync - identity', deviceModel.getIdentity())
            state.device = deviceModel.getDevice();
            state.identity = deviceModel.getIdentity();
            state.token = deviceModel.getToken();
        }
    },
    extraReducers: (builder) => {
        builder.addCase(deviceApi.initDevice.fulfilled, (state, action) => {
            state.status = 'device/succeeded'
            state.statusDevice = 'device/succeeded'
            state.error = null;

            state.device = action.payload.device;
            state.identity = action.payload.identity; 
            state.token = action.payload.token;
        }).addCase(deviceApi.initDevice.pending, (state, action) => {
            state.status = 'device/pending';
            state.statusDevice = 'device/pending';
        }).addCase(deviceApi.initDevice.rejected, (state, action) => {
            state.status = 'device/rejected';
            state.statusDevice = 'device/rejected';
            state.error = action.error;
        }).addCase(deviceApi.login.fulfilled, (state, action) => {
            if(action.payload.device) {
                state.status = 'login/succeeded';
                state.error = null;

                state.device = action.payload.device;
                state.identity = action.payload.identity; 
                state.token = action.payload.token; 
            }
        }).addCase(deviceApi.login.pending, (state, action) => {
            state.status = 'login/pending';
            state.error = null;
        }).addCase(deviceApi.login.rejected, (state, action) => {
            state.status = 'login/rejected'
            state.errorCode = action.payload.code;

            switch(action.payload.code) {
                case config.restError.BadRequest:
                    state.error = "Invalid request";
                    break;
                case config.restError.Forbidden:
                    state.error = "Invalid email or password";
                    break;
                case config.restError.PreconditionFailed:
                    let error = "Precondition failed";
                    if(action.payload.message.enabled) error = action.payload.message.enabled;
                    if(action.payload.message.locked) error = action.payload.message.locked;
                    state.error = error;
                    break;
                default:
                    state.error = "Unknown problem";
                    break;
            }
        }).addCase(deviceApi.logout.pending, (state, action) => {
            state.status = 'logout/pending';
            state.error = null;
        }).addCase(deviceApi.logout.fulfilled, (state, action) => {
            state.status = 'logout/succeeded';
            state.error = null;
            state.identity = null; 
            state.token = null; 
        }).addCase(deviceApi.logout.rejected, (state, action) => {
            state.status = 'logout/rejected';
            state.error = action.error;
        }).addCase(deviceApi.passwordRecovery.fulfilled, (state, action) => {
            state.status = 'recovery/succeeded';
            state.error = null;
            state.identity = null; 
            state.token = null; 
        }).addCase(deviceApi.passwordRecovery.rejected, (state, action) => {
            state.status = 'recovery/rejected';
            state.error = action.error;
        }).addCase(deviceApi.updatePassword.fulfilled, (state, action) => {
            state.status = 'identity/update/succeeded';
        }).addCase(deviceApi.updatePassword.pending, (state, action) => {
            state.status = 'identity/update/pending'
        }).addCase(deviceApi.updatePassword.rejected, (state, action) => {
            state.status = 'identity/update/rejected';
            state.error = action.payload;
        }).addCase(deviceApi.confirmIdentity.fulfilled, (state, action) => {
            // state.status = 'identity/confirm/succeeded'
            state.confirmStatus = true;
        }).addCase(deviceApi.confirmIdentity.rejected, (state, action) => {
            // state.status = 'identity/confirm/rejected';
            state.confirmStatus = false;
        }).addCase(deviceApi.getNewConfirmationCode.fulfilled, (state, action) => {
            state.status = 'identity/confirm/new/succeeded'
        }).addCase(deviceApi.getNewConfirmationCode.pending, (state, action) => {
            state.status = 'identity/confirm/new/pending';
        }).addCase(deviceApi.getNewConfirmationCode.rejected, (state, action) => {
            state.status = 'identity/confirm/new/rejected';
        })
    }
});

const refreshOnSyncCallback = (payload) => {
    Store.dispatch( identitySlice.actions.refreshOnSync(payload) );
};

deviceModel.setOnRefreshCallback(refreshOnSyncCallback);

export const { setIdentityOnUserUpdate, refreshOnSync } = identitySlice.actions;

export default identitySlice.reducer;