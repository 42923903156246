// import { ApiResponse } from "./ApiResponse";
// import { ApiClient } from "../ApiClient";
import { ApiError } from "./ApiError";
// import {Entity} from "..";


export class Pager {
    static RangeUnit = {
        items: "items",
        ids: "ids",
        time: "time"
    }

    totalEntities = 0;
    pageLength = 0;
    unit = Pager.RangeUnit.items;
    from = 0;
    to = 0;
    acceptRanges = [];
    hasPrevPage = false;
    hasNextPage = false;
    entities = null;

    /** @type { ApiClient } */
    apiClient = undefined;

    /**
     * 
     * @param {ApiClient} apiClient 
     * @param {ApiResponse} apiResponse 
     */
    constructor(apiClient, apiResponse) {
        this.apiClient = apiClient;

        const contentRangeLength = apiResponse.headers.get("content-range-length");
        
        if (contentRangeLength !== undefined) {
            this.pageLength = parseInt(contentRangeLength, 10);
        }
        const contentRange = apiResponse.headers.get("content-range");

        // console.warn('Response headers', apiClient.getRoute(), apiResponse.headers);

        if (contentRange !== undefined) {
            // console.warn('Pager', apiClient.getRoute(), contentRange);

            // String like 'items=0-4/6'
            const contentRangeArray = contentRange.split("/");
            const range = contentRangeArray[0];
            const rangeArray = range.split("=");
            const fromToArray = rangeArray[1].split("-");

            this.totalEntities = parseInt(contentRangeArray[1], 10);
            this.unit = (rangeArray[0]);
            this.from = parseInt(fromToArray[0], 10);
            this.to = parseInt(fromToArray[1], 10);

            // try {
            //     console.group('Pager')
            //     console.log('from', this.from)
            //     console.log('pageLength', this.pageLength)
            //     console.log('to', this.to)
            //     console.log('totalEntities', this.totalEntities)
            //     console.groupEnd()
            // } catch(e) {}
            
            (this.from >= this.pageLength && this.pageLength > 0) ? this.hasPrevPage = true : this.hasPrevPage = false;
            // (this.from >= this.pageLength) ? this.hasPrevPage = true : this.hasPrevPage = false;
            (this.to < this.totalEntities) ? this.hasNextPage = true : this.hasNextPage = false;
        }
        const acceptRange = apiResponse.headers?.get("accept-ranges");
        if (acceptRange !== undefined) {
            this.acceptRanges = acceptRange.split(",");
        }
    }

    setApiClient(apiClient) {
        this.apiClient = apiClient;
    }

    setEntities(entities) {
        this.entities = entities;
    }

    getEntities() {
        return this.entities;
    }

    getPrevPage() {
        return new Promise((resolve, reject) => {
            if (this.hasPrevPage) {
                this.apiClient.paginate(this.getPrevFrom(), this.getPrevTo())
                    .then(pager => {
                        resolve(pager);
                    })
                    .catch((error) => {
                        reject(error);
                    });
            } else {
                const apiError = new ApiError();
                apiError.message = "Pager does not have a previous page to dispatch";
                reject(apiError);
            }
        });
    }

    getNextPage() {
        return new Promise((resolve, reject) => {
            if (this.hasNextPage) {
                this.apiClient.paginate(this.getNextFrom(), this.getNextTo(), this.unit)
                    .then(pager => {
                        resolve(pager);
                    })
                    .catch((error) => {
                        reject(error);
                    });
            } else {
                const apiError = new ApiError();
                apiError.message = "Pager does not have a next page to dispatch";
                reject(apiError);
            }
        });
    }

    getPrevFrom() {
        return this.from - this.pageLength < 0 ? 0 : this.from - this.pageLength;
    }

    getPrevTo() {
        return this.to - this.pageLength;
    }

    getNextFrom() {
        return this.from + this.pageLength;
    }

    getNextTo() {
        return this.to + this.pageLength;
    }
}