const nl2br = (str, is_xhtml) => {
    // http://kevin.vanzonneveld.net
    // +   original by: Kevin van Zonneveld (http://kevin.vanzonneveld.net)
    // +   improved by: Philip Peterson
    // +   improved by: Onno Marsman
    // +   improved by: Atli Þór
    // +   bugfixed by: Onno Marsman
    // +      input by: Brett Zamir (http://brett-zamir.me)
    // +   bugfixed by: Kevin van Zonneveld (http://kevin.vanzonneveld.net)
    // +   improved by: Brett Zamir (http://brett-zamir.me)
    // +   improved by: Maximusya
    // *     example 1: nl2br('Kevin\nvan\nZonneveld');
    // *     returns 1: 'Kevin<br />\nvan<br />\nZonneveld'
    // *     example 2: nl2br("\nOne\nTwo\n\nThree\n", false);
    // *     returns 2: '<br>\nOne<br>\nTwo<br>\n<br>\nThree<br>\n'
    // *     example 3: nl2br("\nOne\nTwo\n\nThree\n", true);
    // *     returns 3: '<br />\nOne<br />\nTwo<br />\n<br />\nThree<br />\n'
    var breakTag = (is_xhtml || typeof is_xhtml === 'undefined') ? '<br/>' : '<br>'; // Adjust comment to avoid issue on phpjs.org display
  
    return (str + '').replace(/([^>\r\n]?)(\r\n|\n\r|\r|\n)/g, '$1' + breakTag + '$2');
}

const matchPattern = (pattern, instance) => {
  let matching = null
  let placeholders = pattern.match(/:\w+/g)

  if(placeholders && placeholders.length > 0) {
      let regexText = pattern.replaceAll("\/", "\\/")
      placeholders.forEach( (placeholder) => {
          regexText = regexText.replace(placeholder, "[\\w]+")
      });
      regexText += "$"

      let regExp = new RegExp(regexText,'g')
      matching = instance.match(regExp)

      // console.log(pattern, instance, placeholders, regexText, matching)
  } else {
    matching = pattern === instance ? [instance] : null
    // console.log(pattern, instance, matching)
  }
  
  return (matching !== null)
}

String.prototype.truncate = function(n, ellips = "..." ) {
  return this.substr(0, n-1) + (this.length > n ? ellips : '');
};

String.prototype.nl2br = function () {
  return nl2br(this);
}

String.prototype.matchPattern = function (pattern) {
  return matchPattern(pattern, ""+this);
}

String.prototype.slugify = function (separator = '-') {
  let str = this.normalize('NFD') // replace accented letters with english equivalente è => e'

  str = str.replace(/^\s+|\s+$/g, '') // trim leading/trailing white space
  str = str.toLowerCase() // convert string to lowercase
  str = str.replace(/[^a-z0-9 -]/g, '') // remove any non-alphanumeric characters
           .replace(/\s+/g, separator) // replace spaces with hyphens
           .replace(/-+/g, '-') // remove consecutive hyphens

  return str;
}