import { split } from 'lodash';
import { ApiClient, ApiError, LHSIntFilter, LHSStringFilter, OrderCriteria } from '../../../lib/js-apiclient';
import { ProductApiClient } from './ProductApiClient';
import { ProductMediaApiClient } from './ProductMediaApiClient';
import { ProductVariantApiClient } from './ProductVariantApiClient';
import { ProductVariantMediaApiClient } from './ProductVariantMediaApiClient';

export class ProductModel {
    static TYPE_FASHION = "fashion"

    static STATUS_OPTION = {
        all: { id: null, name: null },                 //for disable filter
        published: { id: 2, name: 'PUBLISHED' },       //for search published
        draft: { id: 1, name: 'DRAFT' },               //for search draft
    }

    static COVER_STATE = {
        all: { value: null, name: 'ALL' },             //for disable filter
        cover: { value: true, name: 'COVER' },         //for search item with cover
        no_cover: { value: false, name: 'NO COVER' },  //for search item without cover
    }

    static CP_OPTION = {
        all: { value: 0, name: 'All PRODUCT' },
        with_warn: { value: 100, name: 'Pending PRODUCT' },
        with_progress: { value: 200, name: 'In Progress PRODUCT' },
        with_error: { value: 300, name: 'Error PRODUCT' },
        with_problem: { value: 400, name: 'Pending/Progress/Error PRODUCT' },
        with_success: { value: 500, name: 'Success PRODUCT' },
    }

    static CPV_OPTION = {
        all: { value: 0, name: 'All VARIANT' },
        with_warn: { value: 100, name: 'Pending VARIANT' },
        with_progress: { value: 200, name: 'In Progress VARIANT' },
        with_error: { value: 300, name: 'Error VARIANT' },
        with_problem: { value: 400, name: 'Pending/Progress/Error VARIANT' },
        with_success: { value: 500, name: 'Success VARIANT' },
    }

    _token = null;
    _type = null;

    constructor(token, type) {
        this._token = (token !== null && token !== undefined) ? token : null;
        this._type = type;
    }

    setToken(token) {
        this._token = (token !== null && token !== undefined) ? token : null;
    }

    setType(type) {
        this._type = type;
    }

    async getProductById(id) {
        const productClient = new ProductApiClient(this._token, this._type, id);
        return productClient.findOne();
    }

    async getProducts(page, term, sorting, filters) {
        const productClient = new ProductApiClient(this._token, this._type);
        productClient.setPagingStep(10);

        if (term != null && term.length > 2) productClient.filterByString(term);

        if (sorting) {
            let orderCriteria = []
            if (sorting.id !== null) orderCriteria.push((new OrderCriteria('id', sorting.id)).setWeight(10))
            if (sorting.sku !== null) orderCriteria.push((new OrderCriteria('sku', sorting.sku)).setWeight(20))
            if (sorting.name !== null) orderCriteria.push((new OrderCriteria('name', sorting.name).setWeight(30)))
            if (sorting.shortDescription !== null) orderCriteria.push((new OrderCriteria('short_description', sorting.shortDescription).setWeight(40)))
            if (sorting.seasonCode !== null) orderCriteria.push((new OrderCriteria('season_code', sorting.seasonCode).setWeight(50)))
            if (sorting.genderCode !== null) orderCriteria.push((new OrderCriteria('gender_code', sorting.genderCode).setWeight(60)))
            if (sorting.categoryCode !== null) orderCriteria.push((new OrderCriteria('category_code', sorting.categoryCode).setWeight(70)))
            if (sorting.brandCode !== null) orderCriteria.push((new OrderCriteria('brand_code', sorting.brandCode).setWeight(80)))
            if (sorting.colorCode !== null) orderCriteria.push((new OrderCriteria('color_alias', sorting.colorCode).setWeight(90)))
            if (sorting.statusName !== null) orderCriteria.push((new OrderCriteria('status_name', sorting.statusName).setWeight(100)))
            if (sorting.qtaTot !== null) orderCriteria.push((new OrderCriteria('qta_tot', sorting.qtaTot).setWeight(110)))
            if (orderCriteria.length > 0) productClient.orderBy(orderCriteria);
        }

        if (filters) {
            let mode = ApiClient.FilterMode.AND
            let filterCriteria = [];

            //filterCriteria.push(new LHSIntFilter('season_enabled', LHSIntFilter.LHSOperator.eq, 1));

            if (filters.sku !== null && filters.sku !== undefined) filterCriteria.push(new LHSStringFilter('sku', LHSStringFilter.LHSOperator.like, filters.sku));
            if (filters.skuEQ !== null && filters.skuEQ !== undefined) filterCriteria.push(new LHSStringFilter('sku', LHSStringFilter.LHSOperator.eq, filters.skuEQ));
            if (filters.barcode !== null && filters.barcode !== undefined) filterCriteria.push(new LHSStringFilter('barcode', LHSStringFilter.LHSOperator.like, filters.barcode));
            if (filters.seasonCode !== null && filters.seasonCode !== undefined) filterCriteria.push(new LHSStringFilter('season_code', LHSStringFilter.LHSOperator.eq, filters.seasonCode));
            if (filters.genderCode !== null && filters.genderCode !== undefined) filterCriteria.push(new LHSStringFilter('gender_code', LHSStringFilter.LHSOperator.eq, filters.genderCode));
            if (filters.categoryCode !== null && filters.categoryCode !== undefined) filterCriteria.push(new LHSStringFilter('category_code', LHSStringFilter.LHSOperator.eq, filters.categoryCode));
            if (filters.brandCode !== null && filters.brandCode !== undefined) filterCriteria.push(new LHSStringFilter('brand_code', LHSStringFilter.LHSOperator.eq, filters.brandCode));
            if (filters.colorCode !== null && filters.colorCode !== undefined) filterCriteria.push(new LHSStringFilter('color_alias', LHSStringFilter.LHSOperator.eq, filters.colorCode));
            if (filters.statusName !== null && filters.statusName !== undefined) filterCriteria.push((new LHSStringFilter('status_name', LHSStringFilter.LHSOperator.eq, filters.statusName)));
            if (filters.coverId !== null && filters.coverId !== undefined) filterCriteria.push((new LHSStringFilter('cover_id', filters.coverId === true ? LHSStringFilter.LHSOperator.nq : LHSStringFilter.LHSOperator.eq, 'NULL')));
            
            if (filters.error !== null && filters.error !== undefined) filterCriteria.push(new LHSStringFilter('error', LHSStringFilter.LHSOperator.like, filters.error));
            if (filters.cpFilter !== null && filters.cpFilter !== undefined) {
                switch(filters.cpFilter) {
                    case ProductModel.CP_OPTION.with_success.value:
                        filterCriteria.push(new LHSIntFilter('cp_warns', LHSStringFilter.LHSOperator.eq, 0))
                        filterCriteria.push(new LHSIntFilter('cp_errors', LHSStringFilter.LHSOperator.eq, 0))
                        filterCriteria.push(new LHSIntFilter('cp_progress', LHSStringFilter.LHSOperator.eq, 0))
                        break
                    case ProductModel.CP_OPTION.with_error.value:
                        filterCriteria.push(new LHSIntFilter('cp_errors', LHSStringFilter.LHSOperator.gt, 0))
                        break
                    case ProductModel.CP_OPTION.with_warn.value:
                        filterCriteria.push(new LHSIntFilter('cp_warns', LHSStringFilter.LHSOperator.gt, 0))
                        break
                    case ProductModel.CP_OPTION.with_progress.value:
                        filterCriteria.push(new LHSIntFilter('cp_progress', LHSStringFilter.LHSOperator.gt, 0))
                        break
                    case ProductModel.CP_OPTION.with_problem.value:
                        filterCriteria.push(new LHSIntFilter('cp_errors', LHSStringFilter.LHSOperator.gt, 0))
                        filterCriteria.push(new LHSIntFilter('cp_warns', LHSStringFilter.LHSOperator.gt, 0))
                        filterCriteria.push(new LHSIntFilter('cp_progress', LHSStringFilter.LHSOperator.gt, 0))
                        mode = ApiClient.FilterMode.OR
                        break
                    default:
                        break
                }
            }

            if (filters.cpvFilter !== null && filters.cpvFilter !== undefined) {
                switch(filters.cpvFilter) {
                    case ProductModel.CPV_OPTION.with_success.value:
                        filterCriteria.push(new LHSIntFilter('cpv_warns', LHSStringFilter.LHSOperator.eq, 0))
                        filterCriteria.push(new LHSIntFilter('cpv_errors', LHSStringFilter.LHSOperator.eq, 0))
                        filterCriteria.push(new LHSIntFilter('cpv_progress', LHSStringFilter.LHSOperator.eq, 0))
                        break
                    case ProductModel.CPV_OPTION.with_error.value:
                        filterCriteria.push(new LHSIntFilter('cpv_errors', LHSStringFilter.LHSOperator.gt, 0))
                        break
                    case ProductModel.CPV_OPTION.with_warn.value:
                        filterCriteria.push(new LHSIntFilter('cpv_warns', LHSStringFilter.LHSOperator.gt, 0))
                        break
                    case ProductModel.CPV_OPTION.with_progress.value:
                        filterCriteria.push(new LHSIntFilter('cpv_progress', LHSStringFilter.LHSOperator.gt, 0))
                        break
                    case ProductModel.CPV_OPTION.with_problem.value:
                        filterCriteria.push(new LHSIntFilter('cpv_errors', LHSStringFilter.LHSOperator.gt, 0))
                        filterCriteria.push(new LHSIntFilter('cpv_warns', LHSStringFilter.LHSOperator.gt, 0))
                        filterCriteria.push(new LHSIntFilter('cpv_progress', LHSStringFilter.LHSOperator.gt, 0))
                        mode = ApiClient.FilterMode.OR
                        break
                    default:
                        break
                }
            }
            
            if (filterCriteria.length > 0) productClient.filterByLHSFilter(filterCriteria, mode);
        }

        return await productClient.fetchPage(page);
    }

    async updateProduct(productId, product) {
        delete product.status;
        delete product.variants;
        delete product.channels;
        const productClient = new ProductApiClient(this._token, this._type, productId);
        return await productClient.save(product);
    }

    async getProductVariants(productId, term, sorting, filters) {
        const productVariantClient = new ProductVariantApiClient(this._token, this._type, productId);
        if (term != null && term.length > 2) productVariantClient.filterByString(term);

        let orderCriteria = []
        if (sorting.id !== null && sorting.id !== undefined) orderCriteria.push((new OrderCriteria('id', sorting.id)).setWeight(10))
        if (sorting.barcode !== null && sorting.barcode !== undefined) orderCriteria.push((new OrderCriteria('barcode', sorting.sku)).setWeight(20))
        if (orderCriteria.length > 0) productVariantClient.orderBy(orderCriteria);

        let filterCriteria = [];
        if (filters.barcode !== null && filters.barcode !== undefined) filterCriteria.push(new LHSStringFilter('barcode', LHSStringFilter.LHSOperator.eq, filters.barcode));
        if (filterCriteria.length > 0) productVariantClient.filterByLHSFilter(filterCriteria);

        return await productVariantClient.findAll();
    }

    async updateVariant(productId, variant) {
        delete variant.status;
        delete variant.size_name;
        delete variant.color_name;

        const variantClient = new ProductVariantApiClient(this._token, this._type, productId, variant.id);
        return await variantClient.save(variant);
    }

    async getProductMedias(productId, sorting) {
        const productMediaClient = new ProductMediaApiClient(this._token, productId);

        let orderCriteria = [];
        if (sorting.weight !== null) orderCriteria.push((new OrderCriteria('weight', sorting.weight)).setWeight(10));
        if (orderCriteria.length > 0) productMediaClient.orderBy(orderCriteria);

        return await productMediaClient.findAll();
    }

    async updateProductMedia(productId, media) {
        const productMediaClient = new ProductMediaApiClient(this._token, productId, media.id);
        return await productMediaClient.save(media);
    }

    async deleteProductMedia(productId, mediaId) {
        const productMediaClient = new ProductMediaApiClient(this._token, productId, mediaId);
        return await productMediaClient.remove();
    }

    async createProductMedia(productId, media) {
        const productMediaClient = new ProductMediaApiClient(this._token, productId);
        return productMediaClient.setContentType("multipart/form-data")
            .save(media)
    }

    async importProductMedia(media) {
        let output = null
        let sku = split(split(media.media.name, ".")[0], "_")[0]
        let products = await this.getProducts(0, null, {}, {sku: sku})

        if(products.entities && products.entities.length > 0 && products.entities[0].id) {
            output = await this.createProductMedia(products.entities[0].id, media)
            output.productId = products.entities[0].id
        } else {
            // throw new ApiError({ code: -1, message: { media: "Sku <b>"+sku+"</b> not fountd" }, url: null})
            throw new ApiError({ code: -1, message: { media: "Fail to upload <b>"+media.media.name+"</b>" }, url: null})
        }
        
        return output
    }

    async importProductMedium(medium, checkName) {
        const results = {
            success: [],
            error: []
        }

        let lastProductSku = null
        let lastProductId = null
        let weight = 0
        let titleList = []

        for (const m of medium) {
            let sku = split(split(m.media.media.name, ".")[0], "_")[0]
            if(sku !== lastProductSku) {
                lastProductSku = sku
                let products = await this.getProducts(0, null, {}, {skuEQ: sku})

                if(products.entities && products.entities.length > 0 && products.entities[0].id) {
                    lastProductId = products.entities[0].id

                    let productMediaList = await this.getProductMedias(lastProductId, { weight: 'DESC' })
                    if(productMediaList.length > 0) {
                        titleList = productMediaList.map(a => a.title)
                        weight = productMediaList[0].weight
                    }
                } else {
                    lastProductId = null
                    weight = 0
                    titleList = []
                }
            }

            if(lastProductId) {
                if(!checkName || !titleList.includes(split(m.media.media.name, ".")[0])) {
                    weight = weight+10
                    m.media.weight = weight
    
                    const output = await this.createProductMedia(lastProductId, m.media)
                    output.productId = lastProductId
    
                    titleList.push(m.media.media.name)
                    results.success.push({...output, uploadUuid: m.uploadUuid})
                } else {
                    results.error[m.uploadUuid] = ({ code: -1, message: { media: "<b>"+m.media.media.name+"</b> already exists" }, url: null})
                }
            } else {
                results.error[m.uploadUuid] = ({ code: -1, message: { media: "Invalid file/sku <b>"+m.media.media.name+"</b>" }, url: null})
            }
        }

        return results
    }

    async getProductVariantMedias(variantId, sorting) {
        const productMediaClient = new ProductVariantMediaApiClient(this._token, variantId);

        let orderCriteria = [];
        if (sorting.weight !== null) orderCriteria.push((new OrderCriteria('weight', sorting.weight)).setWeight(10));
        if (orderCriteria.length > 0) productMediaClient.orderBy(orderCriteria);

        return await productMediaClient.findAll();
    }

    async updateProductVariantMedia(variantId, media) {
        const productMediaClient = new ProductVariantMediaApiClient(this._token, variantId, media.id);
        return await productMediaClient.save(media);
    }

    async deleteProductVariantMedia(variantId, mediaId) {
        const productMediaClient = new ProductVariantMediaApiClient(this._token, variantId, mediaId);
        return await productMediaClient.remove();
    }

    async createProductVariantMedia(variantId, media) {
        const productMediaClient = new ProductVariantMediaApiClient(this._token, variantId);
        return productMediaClient.setContentType("multipart/form-data")
            .save(media)
    }
}