import { 
    createSlice, 
    createAsyncThunk,  
    createEntityAdapter,
    createSelector
} from '@reduxjs/toolkit'

import { ProductModel } from '../models/ProductModel';


const productMediaAdapter = createEntityAdapter()

export const productMediaApi = {
    _getToken: (thunkAPI) => {
        return thunkAPI.getState().identityReducer.token.signature;
    },
    fetchAll: createAsyncThunk(
        'product/media/fetchAll', 
        async ({productId, sorting}, thunkAPI) => {
            try {
                const token = productMediaApi._getToken(thunkAPI);
                const productModel = new ProductModel(token, ProductModel.TYPE_FASHION);

                return await productModel.getProductMedias(productId, sorting);
            } catch (apiError) {
                return thunkAPI.rejectWithValue(apiError.toJson());
            }
        }
    ),
    update: createAsyncThunk(
        'product/media/update', 
        async ({productId, media}, thunkAPI) => {
            try {
                const token = productMediaApi._getToken(thunkAPI);
                const productModel = new ProductModel(token, ProductModel.TYPE_FASHION);

                return await productModel.updateProductMedia(productId, media);
            } catch (apiError) {
                return thunkAPI.rejectWithValue(apiError.toJson());
            }
        }
    ),
    delete: createAsyncThunk(
        'product/media/delete', 
        async ({productId, media}, thunkAPI) => {
            try {
                const token = productMediaApi._getToken(thunkAPI)
                const productModel = new ProductModel(token, ProductModel.TYPE_FASHION)

                await productModel.deleteProductMedia(productId, media.id)
                return media
            } catch (apiError) {
                return thunkAPI.rejectWithValue(apiError.toJson())
            }
        }
    ),
    create: createAsyncThunk(
        'product/media/create', 
        async ({productId, media, uploadUuid}, thunkAPI) => {
            try {
                const token = productMediaApi._getToken(thunkAPI)
                const productModel = new ProductModel(token, ProductModel.TYPE_FASHION)

                const newMedia = await productModel.createProductMedia(productId, media)
                return {...newMedia, uploadUuid: uploadUuid}
            } catch (apiError) {
                return thunkAPI.rejectWithValue({uuid: uploadUuid, error: apiError.toJson()})
            }
        }
    ),
    import: createAsyncThunk(
        'product/media/import', 
        async ({media, uploadUuid}, thunkAPI) => {
            try {
                const token = productMediaApi._getToken(thunkAPI)
                const productModel = new ProductModel(token, ProductModel.TYPE_FASHION)

                const newMedia = await productModel.importProductMedia(media)
                return { ...newMedia, uploadUuid: uploadUuid }
            } catch (apiError) {
                return thunkAPI.rejectWithValue({uuid: uploadUuid, error: apiError.toJson()})
            }
        }
    ),
    imports: createAsyncThunk(
        'product/medium/import', 
        async ({medium, check}, thunkAPI) => {
            try {
                const token = productMediaApi._getToken(thunkAPI)
                const productModel = new ProductModel(token, ProductModel.TYPE_FASHION)

                return await productModel.importProductMedium(medium, check)
            } catch (apiError) {
                return thunkAPI.rejectWithValue(apiError.toJson())
            }
        }
    ),
}

const productMediaSlice = createSlice({
    name: "product/media",
    initialState: productMediaAdapter.getInitialState({
        status: 'idle',
        error: null,
        uploadErrors: {},
    }),
    reducers: {
        sortMedia(state, action) {
            productMediaAdapter.setAll(state, action.payload);
        },
        cleanMedia(state, action) {
            productMediaAdapter.removeAll(state);
            state.uploadErrors = {}
        },
    },
    extraReducers: {
        [productMediaApi.fetchAll.fulfilled]: (state, action) => {
            state.status = 'product/media/fetchAll/succeeded'
            state.error = null;
            productMediaAdapter.setAll(state, action.payload)
        },
        [productMediaApi.fetchAll.rejected]: (state, action) => {
            state.status = 'product/media/fetchAll/rejected'
            state.error = action.payload
        },
        [productMediaApi.create.fulfilled]: (state, action) => {
            state.status = 'product/media/create/succeeded'
            state.error = null;
            productMediaAdapter.addOne(state, action.payload);
        },
        [productMediaApi.create.rejected]: (state, action) => {
            state.status = 'product/media/create/rejected'
            state.uploadErrors = { ...state.uploadErrors, [action.payload.uuid]: action.payload.error}
        },
        [productMediaApi.import.fulfilled]: (state, action) => {
            state.status = 'product/media/import/succeeded'
            state.error = null
            productMediaAdapter.addOne(state, action.payload)
        },
        [productMediaApi.import.rejected]: (state, action) => {
            state.status = 'product/media/import/rejected'
            state.uploadErrors = { ...state.uploadErrors, [action.payload.uuid]: action.payload.error}
        },
        [productMediaApi.imports.fulfilled]: (state, action) => {
            state.status = 'product/medium/import/succeeded'
            state.error = null
            productMediaAdapter.addMany(state, action.payload.success)
            state.uploadErrors = action.payload.error
        },
        [productMediaApi.imports.rejected]: (state, action) => {
            state.status = 'product/medium/import/rejected'
            state.error = action.payload
        },
        [productMediaApi.update.fulfilled]: (state, action) => {
            state.status = 'product/media/update/succeeded'
            state.error = null;
            productMediaAdapter.updateOne(state, action.payload);
        },
        [productMediaApi.update.rejected]: (state, action) => {
            state.status = 'product/media/update/rejected'
            state.error = action.payload
        },
        [productMediaApi.delete.fulfilled]: (state, action) => {
            state.status = 'product/media/delete/succeeded'
            state.error = null;
            productMediaAdapter.removeOne(state, action.payload.id);
        },
        [productMediaApi.delete.rejected]: (state, action) => {
            state.status = 'product/media/delete/rejected'
            state.error = action.payload
        },
    }
});

export const selectSortedProductMedias = createSelector(
    (state) => selectAllProductMedias(state),
    (medias) => {
        return medias.sort((a, b)=>{ 
            return (a.weight - b.weight) 
        });
    }
);

export const selectSortedByTitleProductMedias = createSelector(
    (state) => selectAllProductMedias(state),
    (medias) => {
        return medias.sort((a, b)=>{ 
            if (a.title < b.title) {
            return -1;
            }
            if (a.title > b.title) {
            return 1;
            }
            return 0;
        });
    }
);

export const {
    selectById: selectProductMediaById,
    selectAll: selectAllProductMedias,
} = productMediaAdapter.getSelectors((state) => state.productMediaReducer)

export const { sortMedia, cleanMedia } = productMediaSlice.actions;

export default productMediaSlice.reducer;
